import './Main_page.css';
import { Link } from "react-router-dom";

export default function Main_page() {
  return (
      <div className="Main_page_main_div">
        <h3>Quidquid latine dictum sit, altum sonatur
        </h3>
        <p>
          REMEMBER: You have no obligations here.
          <br />
          Life is short. Make sure you spend as much time as you can, arguing with a complete stranger on the internet about things you don't agree with and don’t care about.
        </p>
        <h6>****</h6>
        <h1 id='main_page_h1'>Meet Pavel </h1>
        <h3 id='main_page_h3'>42 years old, from Bulgaria, with traditional Bulgarian values</h3>
        <div>
          <img src="img/Pavel-12.jpg" alt="pavel_img" />
        </div>

       
       

        <h3>ABOUT ME</h3>
        <p>First, I want to point out the most important thing – I am... somewhat cursed with intelligence.
        Totally different level of perception and communication.
          <br />
          Why I say this is: I don't fit in any of your boxes with human types. Pretty sure you never met anyone like me before.
          <br />
        So. Forget everything you know or think you know about men and people. That’s not me. Open a new blank page and free your mind.
          <br />*<br />
          I’m DTF – Down To Finally be in a relationship with someone who actually cares about us, me, our health and we support each other.
          <br />*<br />
          I took some selfies for this profile and then I saw them, I realized that personality is more important, but not several.
          <br />*<br />
          I don’t smoke, drink, cheat, abuse, gamble or use drugs. I surf this life sober.  I lead a modest, calm lifestyle.
          <br />Curiosity is one of my main characteristics, social, happy, leader. Equity, integrity and fairness are big things for me.
          <br />Strong mentally and physically, not greedy or possessive, like intimacy, cuddling and touch. Very practical Minimalist, down to earth, realist, genuine, friendly, good person, authentic, relaxed, honest, trustworthy, reliable, sarcastic, wholesome, peaceful, slow to anger, fast to laugh, home person, extroverted introvert. A decent, no-baggage guy. The type of guy you bring home, show to your friends and family, snug on the couch, eat pizza and watch a movie.
          <br />Being myself without pretending or do things only so people could like me, don’t care what people think, but do care for my friends, family and environment.
          <br />Family and friends are most important to me. I’ve learned that money come and go, but friends and family can’t be replaced.
          <br />Grateful for the things I have in life. Open-minded (lot of people think this means agreeable). Peaceful (lot of people think that this means harmless).
          <br /><br />
          I hope one day I can pass down those qualities to a family of my own. I have goodness in me and I look for that woman to share with, but I’ve been disappointed in the past and now I’m careful, I don’t take people for good by default. They have to prove themselves.
          <br /><br />
          I was raised by a single mother. Watched her get hurt many times and make mistakes, from which I learned a lot.
          <br />Made me understand how important it is to treat a person right, to support each other and be there for them. My life also taught me to have inner strength and independence. It made me understand just how fucked up society is.
          <br />I’ve had to overcome some really shitty life and that made me a stronger person.
          <br /><br />
          Hobbies: Comedy, memes, cycling, video games, anime, reading, psychology, philosophy, science, technology, DIY.
          <br />And as every human – Music, movies, traveling, parks, nature, seeking like-minded.
          <br /><br />
          I’m also a handyman, I have knowledge about buildings, construction, mechanics and electronics. Can’t fix everything, but I’ll try.
          <br />A dream of mine is to build my own house someday, but also to try van-life as well.
          <br /><br /> I prefer to be single than with the wrong person. A dog is better than a bitch.<br /><br />
          Cancer – Ambivert – Quirkyalone – Chaotic Good – above average IQ – INTP/ESTJ/ENTJ – if these things matter for you.</p>
        <h6>****</h6>

        <h3>WHAT I LOOK FOR</h3>
        <p>Looking for a woman who likes to cycle ( I don’t own a car).
          <br />*<br />
          Ready to date, supportive, available, feminine, wanting FAMILY, decent, fully taking responsibility for herself, curious about life, down to earth, at least some sense of humor, honest, real warm-hearted and open-minded, who invest in the relationship as much as I do, a real partner in life. She must let me steal. I love stealing…. hugs and but grabs.
          <br />Wanting a meaningful relationship in which we grow old and fat together, a woman to work with me for a better future for us.
          <br /><br />
          Ideally, looking for that woman to fit in my life completely, make my existence whole and make me happy taken.
          <br />I have accepted my life and I am comfortable with, to win me over your presence has to fit in, to feel good. To vibe on a spiritual, physical and intellectual level. Someone who inspires me to be better.
          <br />I’d like to find that beautiful type of relationship where life can be shared on a deeper level.
          <br />*<br />
          A big plus is if you recognize your dating/relationships/mental/physical problems and take messures about it.</p>
        <h6>****</h6>

        <h3>DEAL BREAKERS</h3>
        <p>Not single (available), kids, Unhealthy(Body and mind), Selfish, Narcissistic, Woke, Religious, Drama queen, Pretentious, Royal, Promiscuous, Sex worker, Gold digger, ”The Perfect”, "Strong independent", Feminist  and LGBT+++
          <br />
          <br />- Having an animal is more important than having a relationship.
          <br />-  Looking for a man to fix broken live or for a quick thrill and compliments.
          <br />- Remember when you found out there was no Santa? Well, now let me tell you about the "6 Feet, 6 Inches, 6 Figures".</p>
        <h6>****</h6>
        <h5>If you survived all this and you want to talk to the man, click the button bellow. </h5>
        <Link to='/contact_page' ><button>Contact form</button></Link>

 {/* Copyright */}
 <br/>
 <br/>
 <footer>
 <div>
      © 2021 Copyright:{" "}
      <a
        href="https://rocnogu.com"
        rel="noreferrer noopener"
        target="_blank"
        style={{ color: "red" }}
      >
                rocnogu and SOFU Projects

      </a>
    </div>
  </footer>
      </div>
      
  );
}


