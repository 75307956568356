import React from 'react'
import './Terms.css'
import { Link } from "react-router-dom";

 export default function Terms() {
  return (
    <div className="Terms_page">
      <div className="Terms_page_main_div">

        <h1>Terms and conditions</h1>
        <h3>Welcome to Date-This-Guy, a platform dedicated to fostering connections and relationships.
          <br /> Please read the following terms and conditions carefully before using our website:</h3>
        <p>
        These terms constitute a legal agreement between the user and Date-This-Guy.
        <br />
        <br />
          <b> Acceptance of Terms:</b> By accessing or using Date-This-Guy, you agree to comply with and be bound by these terms and conditions.
          <br />If you do not agree with any of these terms, please do not use this website.
          <br />
          <br />

          <b> User Eligibility:</b> You must be at least 18 years old to use this website.
          <br /> By using Date-This-Guy, you confirm that you are of legal age to form a binding contract.
          <br />
          <br />

          <b> Account Registration: </b>  To access certain features of Date-This-Guy, you may be required to register an account.
          <br />You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
          <br />
          <br />

          <b> Accuracy of Information:</b> Users are responsible for providing accurate and up-to-date information on their profiles. Date-This-Guy is not responsible for the accuracy of user-provided content.
          <br />
          <br />

          <b> Code of Conduct:</b> Users agree to behave in a respectful and appropriate manner when using the Date-This-Guy platform.
          <br />This includes refraining from abusive language, harassment, or any behavior that may cause discomfort or offense to other users.
          <br />
          <br />

          <b>User Interactions and Safety:</b>  Date-This-Guy does not conduct background checks on its users.
          Users are advised to take precautions and use their judgment when interacting with others on the platform. Date-This-Guy is not responsible for the actions of its users.
          <br />
          <br />

          <b> User Content:</b> By uploading or sharing content on Date-This-Guy, users grant the website a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, and publish the content.
          <br />
          <br />

          <b> Privacy Policy:</b>  Date-This-Guy respects the privacy of its users.
          <br />Please review our Privacy Policy to understand how we collect, use, and disclose information.
          <br />We do not collect Technical information about the device and the installed OS.
          <br />Functional features of the app are free to use.
          <br />The copyright holder of the application is Bulgarian, registered in Varna Bulgaria.
          <br />The chosen legal system and legislation that will be applied in resolving disputes and regulating legal relations are the EU laws.
          <br />
          <br />

          <b>Third-Party Links:</b> Date-This-Guy may contain links to third-party websites. These links are provided for convenience and do not signify endorsement.
          <br />Date-This-Guy is not responsible for the content of these third-party websites.
          <br />
          <br />

          <b>Termination: </b>  Date-This-Guy reserves the right to terminate or suspend accounts, remove content, or discontinue the website at its discretion, without prior notice.
          <br />
          <br />

          <b>Intellectual Property:</b>  All content and materials on Date-This-Guy, unless otherwise stated, are the property of Date-This-Guy and are protected by copyright, trademark, and other intellectual property laws.
          <br />
          <br />

          <b>Modifications to Terms:</b>  Date-This-Guy reserves the right to modify or update these terms and conditions at any time. Users will be notified of any changes, and continued use of the website constitutes acceptance of the modified terms.
          <br />
          <br />

          <b>Contact Information: </b>  If you have any questions or concerns about these terms and conditions, please contact us at sofu.projects@gmail.com or on the: <Link to='/contact_page' >Contact page</Link>
          <br />
          <br />

          <b>By using Date-This-Guy, you acknowledge that you have read, understood, and agreed to these terms and conditions.
            </b>
          <br /> <br />

          Last updated: 1.7.2024
          <br /> <br />
          Thank you for being a part of Date-This-Guy.
          <br /> <br /> Happy dating!
        </p>
        <p></p>
        <Link to='/' ><button>Go back to the start page</button></Link>

      </div>

 {/* Copyright */}
 <br/>
 <br/>
 <footer>
 <div>
      © 2021 Copyright:{" "}
      <a
        href="https://rocnogu.com"
        rel="noreferrer noopener"
        target="_blank"
        style={{ color: "red" }}
      >
                rocnogu and SOFU Projects

      </a>
    </div>
  </footer>
    </div>
  )
}
